import Utility from '../../../shared/utility';
import breadcrumbComp from '../../common/breadcrumb-comp.vue';
import EncryptUtility from '../js/encrypt-utility';
export default {
  data: () => ({
    objectNameRule: [(v) => !!v || 'Name is required'],

    selectionRule: [(v) => !!v || 'Selection is required'],
    shortDescriptionRule: [
      (v) => v.length <= 250 || 'Description must be 250 characters or less',
        ],
    userId: EncryptUtility.localStorageDecrypt('userID'),
    searchArtifactDetails: '',
    addArtifactDialog: false,
    shortDescription: '',
    editArtifactDialog: false,
    edit: false,
    deleteArtifactDialog: false,
    editObjectName: '',
    editShortDescription: '',
    objectId: 0,
    objectTypeModel: '',
    editObjectType: '',
    backSlash: true,
    headers: [
      {
        text: 'Object Name',
        align: 'start',
        value: 'ObjectName',
        class: 'primary customwhite--text',
        width: '20%'
      },
      {
        text: 'Object Type',
        value: 'ObjectType',
        class: 'primary customwhite--text',
        width: '10%'
      },
      {
        text: 'Short Description',
        value: 'Description',
        class: 'primary customwhite--text',
        width: '45%'
      },
      {
        text: 'Created Date',
        value: 'CreatedDate',
        class: 'primary customwhite--text',
        width: '15%'
      },
      {text: 'Actions', value: 'action', class: 'primary customwhite--text', width: '10%'},
    ],
    artifactsList: [],
    objectType: [
      {objectName: 'Table', value: 'T'},
      {objectName: 'Stored Procedure', value: 'S'},
      {objectName: 'View', value: 'V'},
    ],
  }),

  created() {
    this.getArtifactList();
  },
  methods: {
    // TO load the Artifact
    getArtifactList() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.axios
        .get('co/artifact_list')
        .then((response) => {
          if (response.status == 200) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.artifactsList = responsedata;
            
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          var Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },

    //To add Artifact
    addArtifact() {
      //to check form
      if (this.$refs.addArtifactForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: 'Please Wait...',
        };
        //Data for post
        let artifactedata = {
          Id: this.objectId,
          ObjectName: this.objectName,
          ObjectType: this.objectTypeModel.value,
            ObjectDesc: this.shortDescription,
            UserId: this.UserId  
        };
        //API call
        this.axios
          .post('/co/artifact_create', artifactedata)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
              let Alert = {
                type: 'success',
                isShow: true,
                message: response.data.body.message,
              };
              this.addArtifactDialog = false;

              this.$refs.addArtifactForm.reset();
              this.$store.commit('ConfigModule/Alert', Alert);
              this.getArtifactList();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
              let Alert = {
                type: 'error',
                isShow: true,
                message: response.data.message,
              };
              this.addArtifactDialog = true;
              this.$store.commit('ConfigModule/Alert', Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          });
      } else {
        this.addArtifactDialog = true;
      }
    },

    //to edit  the Artifact items
    editItem(item) {
      this.editArtifactDialog = true;
      this.editObjectName = item.ObjectName;
      this.editShortDescription = item.Description;
      this.editObjectType = item.ObjectType;
      this.objectId = item.Id;
    },

    //To delete Artifacts
    deleteItem(item) {      
      this.objectId = item.Id;
    },
    //update Artifact
    updateArtifact() {
      if (this.$refs.editArtifactForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: 'Please Wait...',
        };

        let artifactdata = {
          Id: this.objectId,
          ObjectName: this.editObjectName,
          ObjectType: this.editObjectType.value,
          ObjectDesc: this.editShortDescription,
          UserId: this.userId
        };
        this.axios
          .post('/co/artifact_update', artifactdata)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
              let Alert = {
                type: 'success',
                isShow: true,
                message: response.data.body.message,
              };              
              this.getArtifactList();
              this.editArtifactDialog = false;
              this.edit = false;
              this.$store.commit('ConfigModule/Alert', Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
              let Alert = {
                type: 'error',
                isShow: true,
                message: response.data.message,
              };
              this.editArtifactDialog = false;
              this.$store.commit('ConfigModule/Alert', Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          });
      } else {
        this.editArtifactDialog = true;
      }
    },

    //delete Artifact
    deleteArtifact() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
        this.axios
           .post(`/co/artifact_delete?id=${this.objectId}&UserId=${this.userId}`)
        .then((response) => {          
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'success',
              isShow: true,
              message: response.data.body.message,
            };

            this.deleteArtifactDialog = false;
            this.$store.commit('ConfigModule/Alert', Alert);
            this.getArtifactList();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.deleteArtifactDialog = false;
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.deleteArtifactDialog = false;
          this.$store.commit('ConfigModule/Alert', Alert);
        });
    },

    // to enable and disable edit option
    allowEdit() {
      this.editArtifactDialog = true;
      this.edit = true;
    },

    //to delete popup
    allowDelete() {
      this.deleteArtifactDialog = true;
    },
    //To Add new menu
    onNewArtifactClick() {
      this.addArtifactDialog = true;
    },

    //To cancel in dialog box
    onClickCancel() {
      this.addArtifactDialog = false;
      this.edit = false;
      this.editArtifactDialog = false;
      this.deleteArtifactDialog = false;
    },

    //To Close Dialog popup
    closeNewArtDialog(){
      this.addArtifactDialog = false;
      this.edit = false;
      this.editArtifactDialog = false;
      this.deleteArtifactDialog = false;
    },
    closeDialogArtifact(){
      this.addArtifactDialog = false;
      this.edit = false;
      this.editArtifactDialog = false;
      this.deleteArtifactDialog = false;
    },
  },
  components: {
    breadcrumbComp
  },
};
